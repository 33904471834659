import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { BlogRecentCard, ContainerList, PageLayout } from '../../components'

const Finance = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {in: [
        "00-feature-reports.png",
      ]}}) {
        images: edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      posts: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: {eq: "blog"},
            tags: {eq: "PMO"}
          }
        }, 
        sort: {fields: [frontmatter___order, frontmatter___date], 
        order: [ASC, DESC]},
        limit: 3
      ) {
        edges {
          node {
            id
            timeToRead
            frontmatter {
              header
              title
              description
              date
              relativeDate: date(fromNow: true)
              featuredimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              featuredpost
              tags
            }
            fields {
              slug
            }
            excerpt(pruneLength: 160)
            html
          }
        }
      }
    }
  `)

  const fluidImage = (path) => {
    const images = data.allFile.images
    const imageNode = images.find(item => item.node.relativePath === path)
    return imageNode && imageNode.node.childImageSharp.fluid
  }
  
  return (
    <PageLayout 
      location={location}
      title="Automated Reports for Corporate Finance"
      description="Hippobyte powers your financial reports with reliable and fresh data to empower financial analysts."
      backgroundColor="#fff"
      pageTheme="dark"
      footer={true}
      pageHero={{
        section: {
          margin: { horizontal: "11vw" }
        },
        main: {
          heading: {
            label: "Automate Project Health Reports; Empower your leads with relevant data.",
          },
          subHeading: {
            label: "Hippobyte provides timely and reliable data to help you meet financial reporting requirements around Planning, Forecasting, and Budgeting activies. Any financial system, any reporting system, nothing to maintain."
          },
          button: {
            label: "Schedule demo",
            path: "/contact/schedule-demo"
          },
          style: { flex: "0 1 60%" },
        },
        aside: {
          content: (
            <Img fluid={fluidImage("00-feature-reports.png")} />
          ),
          style: { flex: "0 1 40%" }
        }
      }}
    >
    <ContainerList
        round="xsmall"
        fontSize="large"
        color="black"
        basis="1/2"
        header={{
          title: "Automate project status/health reports.",
          subTitle: "Build trust among stakeholders by avoiding data issues within financial reports."
        }}
        items={[
          {
            title: "Single Report, Multiple Data Sources",
            description: "Deliver accurate and impactful reports based on multiple sources of data from your existing enterprise applications and data repositories. ",
            image: { 
              theme: "black",
              src: "ThreeSixty",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Fresh and Accurate Data",
            description: "Automated reports in your favorite enterprise BI tool based on data updated in near real-time, and always accurate.",
            image: { 
              theme: "black",
              src: "HeartBaloon",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Reconcile & Audit",
            description: "Reconcile when, where, and why figures might have changed historically between different sources.",
            image: { 
              theme: "black",
              src: "TrackField",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Protect Sensitive Data",
            description: "Protect sensitive data with custom roles and achieve regulatory compliance using column hashing, blocking, and salting.",
            image: { 
              theme: "black",
              src: "Schema",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Use Existing Tools",
            description: "Protect sensitive data with custom roles and achieve regulatory compliance using column hashing, blocking, and salting.",
            image: { 
              theme: "black",
              src: "Schema",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Deploy in Hours",
            description: "Protect sensitive data with custom roles and achieve regulatory compliance using column hashing, blocking, and salting.",
            image: { 
              theme: "black",
              src: "Schema",
              position: "top",
              size: "58px"
            },
          },
        ]}
      />
      <BlogRecentCard 
        title="Learn how to improve your financial analysis with Hippobyte."
        articles={data.posts.edges}
      />
    </PageLayout>
  )
}

export default Finance